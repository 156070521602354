import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import type { RootState } from '@/store';
import { UserType } from '@/common/enum';
import {
  deletePADetailsData,
  getPADataSet,
  postPADetailsData,
  getPADataByID,
} from '@/services/pa.endpoints.ts';
import { PAData, PADataSet } from 'interfaces/pa.interface.ts';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import { putDocumentsBulk, findByQuery } from 'store/db/db.thunk.ts';
import { updateFetchProgress, updateSyncProgress, resetProgress } from 'store/pa/pa.slice.ts';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

export const fetchPADataSet = createAsyncThunk(
  'fetchPADataSet',
  async (payload: { category?: string; incidentID?: number } | undefined, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { health } = store.connection;
      const { selectedGroup, selectedGroupID, allSubGroups, userType } = store.accountsInfo;
      const { selectedIncidentForParcelSyncID } = store.incidents;
      const selectedApplicant = store.applicants.selectedApplicant;
      if (!userType || !selectedIncidentForParcelSyncID) return;

      const inc = payload?.incidentID ?? selectedIncidentForParcelSyncID;
      const applicantId = selectedApplicant?.id;
      let baseURL = '/api';
      const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      let filters = `?filters[incident_id]=${inc}&filters[group_id]=${activeGroup}`;
      if (userType === UserType.STATE_USER) {
        baseURL += '/pa_state';
      } else {
        baseURL += '/pa';
      }
      if (payload?.category) {
        filters += `&filters[category]=${payload.category}`;
      }
      if (allSubGroups && allSubGroups.length) {
        allSubGroups.forEach((subgroup) => {
          filters += `&filters[group_id]=${subgroup.pvGroupID}`;
        });
      }
      if (applicantId) {
        filters += `&filters[applicant_id]=${applicantId}`;
      }
      baseURL += filters;
      const endpoint = getPADataSet(`${baseURL}&limit=2`);
      let data: any | null = null;
      let result: PAData[] = [];

      // If the connection is healthy, fetch the data from the server, else serve it from the local db
      if (health) {
        data = await apiFetch<PADataSet>(endpoint).then((res) => res.data ?? null);
        if (data?.dataset?.length) {
          thunkAPI.dispatch(resetProgress());
          const limit = 500;
          const totalCount = data?.count ?? 0;
          const totalPages = Math.ceil(totalCount / limit);
          const combinedData: any[] = [];

          // Fetch all data in chunks
          for (let i = 0; i < totalPages; i++) {
            const offset = i * limit;
            const endpoint = getPADataSet(`${baseURL}&limit=${limit}&offset=${offset}`);
            const dataResponse = await apiFetch<PADataSet>(endpoint).then(
              (res) => res.data ?? null
            );
            combinedData.push(...(dataResponse?.dataset ?? []));
            const fetchProgress = Math.round(((i + 1) / totalPages) * 100);
            thunkAPI.dispatch(updateFetchProgress(fetchProgress));
          }

          // Process the data and prepare for bulk insertion/update
          const bulkDocsToPut = combinedData.map((item) => {
            item.dma_category = 'pa';
            if (item?.utilities_out) {
              item.utilities_out = item.utilities_out.split(',').map((str: string) => str.trim());
            }
            if (item?.insurance_type) {
              item.insurance_type = item.insurance_type.split(',').map((str: string) => str.trim());
            }
            return item;
          });

          // Perform bulk operation on the database
          const existingDocs = (await findByQuery({
            selector: { id: { $in: bulkDocsToPut.map((doc) => doc.id) } },
          })) as PAData[];

          const mergedDocs = bulkDocsToPut.map((doc) => {
            const existingDoc = existingDocs.find((eDoc: any) => eDoc.id === doc.id);
            return existingDoc &&
              (existingDoc.isUnsaved || existingDoc.updated_at === doc.updated_at)
              ? existingDoc
              : { ...existingDoc, ...doc };
          });

          if (mergedDocs.length > 0) {
            await putDocumentsBulk(mergedDocs);
          }

          // Track the progress of the sync operation
          mergedDocs.forEach((_, index) => {
            const syncProgress = Math.round(((index + 1) / mergedDocs.length) * 100);
            thunkAPI.dispatch(updateSyncProgress(syncProgress));
          });

          result = mergedDocs;
        }

        // Fetch all unsaved items from local db and merge with the results
        const newLocalItems = (await findByQuery({
          selector: { dma_category: 'pa', isUnsaved: true, id: null },
        })) as PAData[];
        result.push(...newLocalItems);
      } else {
        // If offline, fetch all the data from the local database
        const paDataFromPouchDB = (await findByQuery({
          selector: { dma_category: 'pa' },
        })) as PAData[];
        if (paDataFromPouchDB.length) result = paDataFromPouchDB;
      }

      return result;
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null && 'message' in error) {
          message = String(error.message);
        }
      }
      throw Error(message);
    }
  }
);

export const fetchPADataByID = createAsyncThunk(
  'pa/fetchPADataByID',
  async (id: string, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { userType } = store.accountsInfo;
      if (!userType) return;
      const url = `/api/${userType === UserType.STATE_USER ? 'pa_state' : 'pa'}/${id}`;
      const endpoint = getPADataByID(url);
      const result = await apiFetch<PAData>(endpoint).then((res) => res.data ?? null);
      return result;
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const savePAData = createAsyncThunk('pa/savePAData', async (payload: any, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { selectedGroupID, selectedGroup, userType, account } = store.accountsInfo;
    const { selectedIncidentForParcelSync } = store.incidents;
    const { accessType } = store.authToken;
    const { isJPDA_PA_Active } = store.incidents;
    const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
    if (!activeGroup || !userType || !account || !selectedIncidentForParcelSync) return;
    // *: THE RULE IS:
    // When a PA entry is updated if county IS NOT IN JPDA active status,
    // update the corresponding pa_state entry.
    // pa_state editing -- it can only be done when the county IS IN JPDA active status
    let URL = '/api';
    let URLState = '/api/pa_state';
    let isNew = true;
    if (userType === UserType.STATE_USER) {
      if (
        !isJPDA_PA_Active &&
        (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
      ) {
        URL += '/pa';
      } else {
        URL += '/pa_state';
      }
    } else URL += '/pa';

    if (payload?.id) {
      URL += `/${payload.id}`;
      URLState += `/${payload.id}`;
      isNew = false;
    }

    const endpoint_PA = postPADetailsData(URL, payload);
    const endpoint_PA_STATE = postPADetailsData(URLState, payload);
    if (
      userType === UserType.STATE_USER &&
      !isNew &&
      !isJPDA_PA_Active &&
      (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
    ) {
      // *: Update pa_state entry
      await apiFetch<PADetail>(endpoint_PA_STATE).then((res) => res.data ?? null);
    }
    return await apiFetch<PADetail>(endpoint_PA).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const deletePAData = createAsyncThunk(
  'DELETE_PA_DATA',
  async (payload: { id: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { userType } = store.accountsInfo;
      if (!userType) return;
      let baseURL = '/api/pa';
      if (userType === UserType.STATE_USER) {
        baseURL = '/api/pa_state';
      }
      baseURL += `/${payload.id}`;

      const endpoint = deletePADetailsData(baseURL);
      return await apiFetch<{ Success: boolean }>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
