/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import type { RootState } from '@/store';
import { UserType } from '@/common/enum';
import { getIADataByID, getIADataSet, postIAData } from '@/services/ia.endpoint.ts';
import { IADataSet, IADetails } from 'interfaces/ia.interface.ts';
import { findByQuery, putDocumentsBulk } from 'store/db/db.thunk.ts';
import { updateFetchProgress, updateSyncProgress, resetProgress } from './ia.slice';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

export const fetchIADataSet = createAsyncThunk(
  'ia/fetchIADataSet',
  async (payload: { incidentID?: number } | undefined, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { health } = store.connection;
      const { selectedGroupID, selectedGroup, allSubGroups, userType } = store.accountsInfo;
      const { selectedIncidentForParcelSyncID } = store.incidents;
      if (!userType || !selectedIncidentForParcelSyncID) return;
      const inc = payload?.incidentID ?? selectedIncidentForParcelSyncID;
      const activeGroupID = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      let url = `/api/ia?filters[incident_id]=${inc}&filters[group_id]=${activeGroupID}`;
      if (userType === UserType.STATE_USER) {
        url = `/api/ia_state?filters[incident_id]=${inc}&filters[group_id]=${activeGroupID}`;
      }
      if (allSubGroups && allSubGroups.length) {
        allSubGroups.forEach((subgroup) => {
          url += `&filters[group_id]=${subgroup.pvGroupID}`;
        });
      }
      const defaultURL = url;
      const endpoint = getIADataSet(`${url}&limit=2`);
      let data: any | null = null;
      let result: IADetails[] = [];

      if (health) {
        data = await apiFetch<IADataSet>(endpoint).then((res) => res.data ?? null);
        if (data?.dataset?.length) {
          thunkAPI.dispatch(resetProgress());
          const limit = 500;
          const totalCount = data?.count ?? 0;
          const totalPages = Math.ceil(totalCount / limit);
          const combinedData: any[] = [];

          for (let i = 0; i < totalPages; i++) {
            const offset = i * limit;
            const pageEndpoint = getIADataSet(`${defaultURL}&limit=${limit}&offset=${offset}`);
            const dataResponse = await apiFetch<IADataSet>(pageEndpoint).then(
              (res) => res.data ?? null
            );
            combinedData.push(...(dataResponse?.dataset ?? []));
            const fetchProgress = Math.round(((i + 1) / totalPages) * 100);
            thunkAPI.dispatch(updateFetchProgress(fetchProgress));
          }

          const bulkDocsToPut: any[] = [];
          const foundDocsById: Record<string, any> = {};

          const idsToQuery = combinedData.map((item) => item.id);
          const foundDocs = (await findByQuery({ selector: { id: { $in: idsToQuery } } })) as any;

          foundDocs.forEach((doc: any) => {
            foundDocsById[doc.id] = doc;
          });

          for (let i = 0; i < combinedData.length; i++) {
            const item = combinedData[i];
            item.dma_category = 'ia';
            if (item?.utilities_out)
              item.utilities_out = item.utilities_out.split(',').map((item: any) => item.trim());
            if (item?.insurance_type)
              item.insurance_type = item.insurance_type.split(',').map((item: any) => item.trim());

            const foundDoc = foundDocsById[item.id];
            if (foundDoc?.isUnsaved || foundDoc?.updated_at === item.updated_at) {
              result.push(foundDoc);
            } else {
              const updatedObject = foundDoc ? { ...foundDoc, ...item } : item;
              bulkDocsToPut.push(updatedObject);
              result.push(updatedObject);
            }
            if (i % 100 === 0 || i === combinedData.length - 1) {
              const syncProgress = Math.round(((i + 1) / combinedData.length) * 100);
              thunkAPI.dispatch(updateSyncProgress(syncProgress));
            }
          }

          if (bulkDocsToPut.length > 0) {
            await putDocumentsBulk(bulkDocsToPut);
          }

          const newLocalItems = (await findByQuery({
            selector: { dma_category: 'ia', isUnsaved: true, id: '' },
          })) as any;
          result.push(...newLocalItems);
        }
      } else {
        const iaDataFromPouchDB = (await findByQuery({
          selector: { dma_category: 'ia' },
        })) as any;
        if (iaDataFromPouchDB.length) result = iaDataFromPouchDB;
      }

      return result;
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const fetchIADataByID = createAsyncThunk(
  'ia/fetchIADataByID',
  async (id: string, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { userType } = store.accountsInfo;
      if (!userType) return;
      const url = `/api/${userType === UserType.STATE_USER ? 'ia_state' : 'ia'}/${id}`;
      const endpoint = getIADataByID(url);
      return await apiFetch<IADetails>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const saveIAData = createAsyncThunk('ia/saveIAData', async (payload: any, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { userType } = store.accountsInfo;
    const { accessType } = store.authToken;
    const { isJPDA_IA_Active } = store.incidents;
    if (!userType) return;
    let isNew = true;
    let url: string = '/api';
    let urlState: string = '/api/ia_state';
    // *: THE RULE IS:
    // When an IA entry is updated if county IS NOT IN JPDA active status,
    // update the corresponding ia_state entry.
    // ia_state editing -- it can only be done when the county IS IN JPDA active status
    // If the county is in JPDA active status, new IA state entries will be created in ia_state only
    if (userType === UserType.STATE_USER) {
      if (
        !isJPDA_IA_Active &&
        (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
      ) {
        url += '/ia';
      } else {
        url += '/ia_state';
      }
    } else url += '/ia';

    if (payload.id) {
      url += `/${payload.id}`;
      urlState += `/${payload.id}`;
      isNew = false;
    }

    const endpoint_IA = postIAData(url, payload);
    const endpoint_IA_STATE = postIAData(urlState, payload);
    if (
      userType === UserType.STATE_USER &&
      !isNew &&
      !isJPDA_IA_Active &&
      (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
    ) {
      // *: Update ia_state entry
      await apiFetch<{ success: boolean }>(endpoint_IA_STATE).then((res) => res.data ?? null);
    }
    return await apiFetch<{ success: boolean }>(endpoint_IA).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
